import React, { Component, createRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group'
import { trackGa } from '@components/GoogleTracking'
import { Modal } from 'react-bootstrap'

import Layout from '@components/layout/Layout'
import EssScreenerComponent from '@components/screeners/ess-screener/ess-screener.component'
import SnsScreenerComponent from '@components/screeners/sns-screener/sns-screener.component'
import ResultsComponent from '@components/screeners/results/results.component'
import SiteLink from '@components/SiteLink'
import LearnMoreComponent from '@components/learn-more/learn-more.component'
import OptionalInformation from '@components/screeners/optional-info/optional-info.component'
import ShareResultsExplanation from '@components/screeners/share-results-explanation/share-results-explanation.component'
import ReferenceComponent from '@components/references/reference.component'
import { scrollToRef } from '@utils/scrollUtil'
import ReadNext from '@components/read-next-button/read-next-button'
import { references } from '@components/references/screeners-references'
import { win } from '@src/app-utils'

import './symptom-screener.scss'

class SymptomScreener extends Component {
  constructor(props) {
    super(props)

    this.essRef = createRef()
    this.snsRef = createRef()

    this.state = {
      EssScore: 0,
      SnsScore: 0,
      essCalculated: false,
      snsCalculated: false,
      resultsHidden: true,
      iOS: false,
      calculating: false,
      gender: '',
      age: '',
      showFinishQuestionsModal: false,
      showOptionalInfoModal: false,
      whichCalc: '',
      showEssDownloadModal: false,
      showSnsDownloadModal: false,
      isEssResults: false,
      sticky: false,
      unscrolled: false,
      sectionSns: false,
      startSetup: false,
      showEssEmailModal: false,
      showSnsEmailModal: false,
    }

    this.calcEssValues = this.calcEssValues.bind(this)
    this.calcSnsValues = this.calcSnsValues.bind(this)
    this.setResponse = this.setResponse.bind(this)
    this.setDevice = this.setDevice.bind(this)
    this.essScoreTracking = this.essScoreTracking.bind(this)
    this.snsScoreTracking = this.snsScoreTracking.bind(this)
    this.qualificationESS = this.qualificationESS.bind(this)
    this.qualificationSNS = this.qualificationSNS.bind(this)
    this.optionalInfoGenderTracking = this.optionalInfoGenderTracking.bind(this)
    this.optionalInfoAgeTracking = this.optionalInfoAgeTracking.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.calculate = this.calculate.bind(this)
    this.getMultiplyerEss = this.getMultiplyerEss.bind(this)
    this.showResultsModal = this.showResultsModal.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.setAutocompleteStartup = this.setAutocompleteStartup.bind(this)
    this.trackBothScoresAndQualifieds = this.trackBothScoresAndQualifieds.bind(this)

    this.learnMoreContent = {
      className: 'learn-more-wrapper-gray',
      title: 'AASM provides quality measures for managing patients with narcolepsy',
      supText: '3',
      link: '/disease-management/treatment-decisions/',
      linkLabel: 'Discover these approaches',
      tracking: 'narcolepsy symptom screener,	click, screener-cta-management considerations',
    }

    this.EssScores = {}
    this.SnsScores = {}
  }

  handleCloseModal() {
    this.setState({
      showFinishQuestionsModal: false,
      showOptionalInfoModal: false,
      showDownloadModal: false,
      showEssEmailModal: false,
      startSetup: false,
      showSnsEmailModal: false,
      showEssDownloadModal: false,
      showSnsDownloadModal: false,
    })
  }

  setAutocompleteStartup() {
    this.setState({ startSetup: true })
  }

  showResultsModal(type, resultType) {
    if (resultType === 'download') {
      if (type === 'ess') {
        this.setState({ showEssDownloadModal: true })
      } else this.setState({ showSnsDownloadModal: true })
      if (type === 'ess') {
        this.setState({ isEssResults: true })
      } else this.setState({ isEssResults: false })
    } else {
      if (type === 'ess') {
        this.setState({ showEssEmailModal: true })
      } else this.setState({ showSnsEmailModal: true })
      if (type === 'ess') {
        this.setState({ isEssResults: true })
      } else this.setState({ isEssResults: false })
    }
  }

  componentDidMount() {
    this.setDevice()
    window.addEventListener && window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }
  setDevice() {
    var device = /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream
    if (device) {
      return true
    }
  }

  setResponse(responseValue) {
    this[responseValue.quizGroup] = {
      ...this[responseValue.quizGroup],
      [responseValue.radioGroup]: responseValue.number,
    }
  }

  setEssResultsFalse() {
    this.setState({ isEssResults: false })
  }

  calcEss() {
    let total = 0

    for (let key in this.EssScores) {
      if (!isNaN(this.EssScores[key]) && this.EssScores[key] !== false) total += parseInt(this.EssScores[key])
    }

    trackGa(`narcolepsy symptom screener, ess_score, ${this.qualificationESS(total)}`)
    localStorage.setItem('EssScores', JSON.stringify(this.EssScores))
    localStorage.setItem('EssScore', total)

    return total
  }

  qualificationESS(total) {
    if (total < 10) return 'not qualified'
    else return 'qualified'
  }

  qualificationSNS(total) {
    if (total >= 0) return 'not qualified'
    else return 'qualified'
  }

  optionalInfoGenderTracking(gender) {
    if (gender) {
      if (gender === 'male') {
        this.setState({ gender: 'male' })
      } else {
        this.setState({ gender: 'female' })
      }
    }
  }

  optionalInfoAgeTracking(age) {
    if (age) {
      if (age === '1') {
        this.setState({ age: 'age18' })
      } else if (age === '2') {
        this.setState({ age: 'age25' })
      } else if (age === '3') {
        this.setState({ age: 'age35' })
      } else if (age === '4') {
        this.setState({ age: 'age45' })
      } else if (age === '5') {
        this.setState({ age: 'age55' })
      }
    }
  }

  calculate() {
    if (this.state.whichCalc === 'ess') {
      this.setState({ showOptionalInfoModal: false })
      this.calcEssValues()
    } else {
      this.setState({ showOptionalInfoModal: false })
      this.calcSnsValues()
    }
  }

  calcSns() {
    let total = 20,
      questionMaps = [6, 9, -5, -11, -13]

    for (let i = 0; i < 5; i++) {
      let answerKey = `sns${i + 1}`
      if (this.SnsScores[answerKey]) {
        total += questionMaps[i] * this.SnsScores[answerKey]
      }
    }
    trackGa(`narcolepsy symptom screener, sns_score, ${this.qualificationSNS(total)}`)
    localStorage.setItem('SnsScores', JSON.stringify(this.SnsScores))
    localStorage.setItem('SnsScore', total)

    return total
  }

  essScoreTracking() {
    let scores = this.EssScores
    Object.entries(scores).map(function (key) {
      const scoreKey = key[0].replace('ess', 'narcolepsy symptom screener, ess_q')
      const scoreValue = key[1].toString()
      trackGa(`${scoreKey}, ${scoreValue}`)
    })
  }

  snsScoreTracking() {
    let scores = this.SnsScores
    Object.entries(scores).map(function (key) {
      const scoreKey = key[0].replace('sns', 'narcolepsy symptom screener, sns_q')
      const scoreValue = `${key[1]},`.toString()
      trackGa(`${scoreKey}, ${scoreValue}`)
    })
  }

  captureOptionalInfo(screenerType) {
    if (screenerType === 'ess') {
      const objectLength = Object.keys(this.EssScores).length
      if (objectLength < 8) {
        this.setState({ showFinishQuestionsModal: true })
        return
      } else this.setState({ showOptionalInfoModal: true, whichCalc: 'ess' })
    } else {
      const objectLength = Object.keys(this.SnsScores).length
      if (objectLength < 5) {
        this.setState({ showFinishQuestionsModal: true })
        return
      } else this.setState({ showOptionalInfoModal: true, whichCalc: 'sns' })
    }
  }

  calcEssValues() {
    this.essScoreTracking()
    this.calculatingAnimStart()
    trackGa('narcolepsy symptom screener, ess_score, calculate')
    trackGa(`narcolepsy symptom screener,	optional - ess, ${this.state.gender} `)
    trackGa(`narcolepsy symptom screener,	optional - ess, ${this.state.age} `)
    setTimeout(() => {
      this.setState(
        {
          EssScore: this.calcEss(),
          essCalculated: true,
          resultsHidden: false,
          iOS: this.setDevice(),
        },
        () => {
          this.calcLeftEss()
          this.scrollToResults('ess')
        }
      )
      trackGa(`narcolepsy symptom screener, ess_score, ${this.state.EssScore}`)
      this.calculatingAnimFinish()
      this.trackBothScoresAndQualifieds()
    }, 1500)
  }

  calcSnsValues() {
    this.snsScoreTracking()
    this.calculatingAnimStart()
    trackGa('narcolepsy symptom screener, sns_score, calculate')
    trackGa(`narcolepsy symptom screener,	optional - sns, ${this.state.gender} `)
    trackGa(`narcolepsy symptom screener,	optional - sns, ${this.state.age} `)
    setTimeout(() => {
      this.setState(
        {
          SnsScore: this.calcSns(),
          snsCalculated: true,
          resultsHidden: false,
          iOS: this.setDevice(),
        },
        () => {
          this.calcLeftSns()
          this.scrollToResults()
        }
      )
      trackGa(`narcolepsy symptom screener, sns_score, ${this.state.SnsScore}`)
      this.calculatingAnimFinish()
      this.trackBothScoresAndQualifieds()
    }, 1500)
  }

  trackBothScoresAndQualifieds() {
    let sns = win && localStorage.getItem('SnsScore')
    let ess = win && localStorage.getItem('EssScore')
    if (sns && ess) {
      trackGa(`narcolepsy symptom screener, ess_sns_score, ess: ${ess}; sns: ${sns}`)
      if (sns < 0 && ess > 10) trackGa('narcolepsy symptom screener, ess_sns_score, qualified')
      
    }
  }

  scrollToResults(resultsType) {
    if (resultsType === 'ess') {
      let results = document.getElementById('scroll-to-results-ess')
      if (results) {
        window.scrollTo(0, results.offsetTop - 200)
      }
    } else {
      let results = document.getElementById('scroll-to-results-sns')
      if (results) {
        window.scrollTo(0, results.offsetTop - 200)
      }
    }
  }

  onScroll() {
    this.state.unscrolled && this.setState({ unscrolled: false })
    const fromTop = document.documentElement.scrollTop
    // Check if screener nav should stick
    let scrollDepthNeeded
    if (window.innerWidth >= 1200) {
      scrollDepthNeeded = 59
    } else if (window.innerWidth >= 992) {
      scrollDepthNeeded = 30
    } else {
      scrollDepthNeeded = 43
    }

    if (fromTop > scrollDepthNeeded && !this.state.sticky) {
      this.setState({ sticky: true })
    } else if (fromTop <= scrollDepthNeeded && this.state.sticky) {
      this.setState({ sticky: false })
    }
    // Check which screener they're scrolled to
    if (fromTop >= this.snsRef.current.offsetTop - 120 && !this.state.unscrolled) {
      this.setState({ sectionSNS: true })
    } else if (fromTop < this.snsRef.current.offsetTop - 120 && !this.state.unscrolled) {
      this.setState({ sectionSNS: false })
    }
  }

  calculatingAnimStart() {
    let btnCalculate = document.getElementsByClassName('btn-calculate')
    let btnCalculating = document.getElementsByClassName('btn-calculating')
    let opacityContainer = document.getElementsByClassName('opacity-container')
    if (btnCalculate[0]) {
      btnCalculate[0].style.display = 'none'
    }
    if (btnCalculating[0]) {
      let windowSize = window.innerWidth
      btnCalculating[0].style.display = 'flex'
      btnCalculating[0].style.zIndex = '200'
      if (windowSize >= 320 && windowSize < 341) {
        btnCalculating[0].style.bottom = '610px'
      } else if (windowSize >= 341 && windowSize < 375) {
        btnCalculating[0].style.bottom = '585px'
      } else if (windowSize >= 375 && windowSize < 418) {
        btnCalculating[0].style.bottom = '568px'
      } else if (windowSize >= 418 && windowSize < 446) {
        btnCalculating[0].style.bottom = '550px'
      } else if (windowSize >= 446 && windowSize < 460) {
        btnCalculating[0].style.bottom = '533px'
      } else if (windowSize >= 460 && windowSize < 527) {
        btnCalculating[0].style.bottom = '508px'
      } else if (windowSize >= 527 && windowSize < 576) {
        btnCalculating[0].style.bottom = '490px'
      } else if (windowSize >= 576 && windowSize < 616) {
        btnCalculating[0].style.bottom = '503px'
      } else if (windowSize >= 616 && windowSize < 652) {
        btnCalculating[0].style.bottom = '478px'
      } else if (windowSize >= 652 && windowSize < 750) {
        btnCalculating[0].style.bottom = '460px'
      } else if (windowSize >= 750 && windowSize < 994) {
        btnCalculating[0].style.bottom = '360px'
      } else if (windowSize >= 994 && windowSize < 1170) {
        btnCalculating[0].style.bottom = '325px'
        btnCalculating[0].style.marginLeft = '-137px'
      } else if (windowSize >= 1170) {
        btnCalculating[0].style.bottom = '305px'
      }
    }
    if (opacityContainer[0]) {
      opacityContainer[0].style.opacity = '0.5'
    }
  }

  calculatingAnimFinish() {
    let opacityContainer = document.getElementsByClassName('opacity-container')
    let btnCalculating = document.getElementsByClassName('btn-calculating')
    if (opacityContainer[0]) {
      opacityContainer[0].style.opacity = '1'
    }
    if (btnCalculating[0]) {
      btnCalculating[0].style.display = 'none'
    }
  }

  calcLeftEss() {
    let multiplier = 0
    let left = 0
    let score = this.state.EssScore
    let windowWidth = window.innerWidth
    let max = 0

    if (windowWidth < 750) {
      multiplier = 9.35
      left = score * multiplier
      max = 220
      this.moveEss(left, 'ess-marker-verticle', max)
    } else if (windowWidth >= 750 && windowWidth < 994) {
      multiplier = this.getMultiplyerEss(score, 'ess')
      left = score * multiplier
      max = 390
      this.moveEss(left, 'ess-marker-horizontal-md', max)
    } else if (windowWidth >= 994) {
      multiplier = this.getMultiplyerEss(score, 'ess')
      left = score * multiplier
      max = 492
      this.moveEss(left, 'ess-marker-horizontal-lg', max)
    }
  }

  getMultiplyerEss(score, type) {
    if (type === 'ess') {
      if (score <= 12) return 22.4
      else if (score > 12 && score <= 20) return 23.6
      else return 24.7
    } else {
      if (score >= 0) {
        return 8.95
      } else return 9.1
    }
  }

  getSnsScoreForScale() {
    let snsScore = this.state.SnsScore

    if (snsScore < -50) {
      snsScore = -50
    }

    if (snsScore > 20) {
      snsScore = 20
    }

    return snsScore
  }

  calcLeftSns() {
    let multiplier = 0
    let left = 0
    let score = this.getSnsScoreForScale() + 50
    let windowWidth = window.innerWidth
    let max = 0

    if (windowWidth < 750) {
      multiplier = 4.3
      left = score * multiplier
      max = 270
      this.moveSns(left, 'sns-marker-verticle', max)
    } else if (windowWidth >= 750 && windowWidth < 994) {
      multiplier = this.getMultiplyerEss(score, 'sns')
      left = score * multiplier
      max = 600
      this.moveSns(left, 'sns-marker-horizontal-md', max)
    } else if (windowWidth >= 944) {
      multiplier = this.getMultiplyerEss(score, 'sns')
      left = score * multiplier
      max = 600
      this.moveSns(left, 'sns-marker-horizontal-lg', max)
    }
  }

  moveSns(left, className, max) {
    var elem = document.getElementsByClassName(className)
    var pos = 0
    var id = setInterval(frame, 5)
    function frame() {
      if (elem[0]) {
        if (max === 270) {
          if (left <= max) {
            if (pos > left) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          } else {
            if (pos > max) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          }
        } else {
          if (pos >= max) {
            return
          } else if (pos > left) {
            clearInterval(id)
          } else {
            pos++
            elem[0].style.left = pos + 'px'
          }
        }
      }
    }
  }

  moveEss(left, className, max) {
    var elem = document.getElementsByClassName(className)
    var pos = 0
    var id = setInterval(frame, 5)
    function frame() {
      if (elem[0]) {
        if (max === 220) {
          if (left <= max) {
            if (pos >= left) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          } else {
            if (pos >= max) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          }
        } else {
          if (pos >= left) {
            clearInterval(id)
          } else {
            pos++
            elem[0].style.left = pos + 'px'
          }
        }
      }
    }
  }

  render() {
    const readNext = {
      readNextContent: 'Narcolepsy diagnostic criteria',
      goTo: '/screening-and-diagnosis/diagnostic-criteria/',
      tracking: 'narcolepsy symptom screener,	click,	diagnostic criteria - symptom screener',
    }

    return (
      <Layout addedClass='page-screeners' location={this.props.location}>
        <div className="symptom-screener-page">
          <div className="opacity-container">
            <div className="primary-container">
              <div className={`${this.state.sticky ? 'page-scrolled-container' : 'none'}`} />
              <div className={`screener-nav ${this.state.sticky ? 'screener-nav-sticky' : ''}`}>
                <div className="screener-container">
                  <div className="screener-nav-links">
                    <p className="nav-title">SYMPTOM SCREENERS:</p>
                    <p
                      className={`screener-link ${!this.state.unscrolled && !this.state.sectionSNS ? 'active' : ''}`}
                      onClick={() => scrollToRef(this.essRef)}
                    >
                      MEASURING SLEEPINESS
                    </p>
                    <div className="nav-pipe">|</div>
                    <p
                      className={`screener-link ${!this.state.unscrolled && this.state.sectionSNS ? 'active' : ''}`}
                      onClick={() => scrollToRef(this.snsRef)}
                    >
                      EXPLORING SYMPTOMS
                    </p>
                  </div>
                </div>
              </div>
              <h1 className="main-page-header">Screening for Narcolepsy Is Important</h1>
              <div className="paragraph-learn-more">
                <div className="header-paragraph-wrapper">
                  <p className="body-copy">
                    During appointments, ask your patients to complete the{' '}
                    <SiteLink
                      className="inline-link"
                      to="/screening-and-diagnosis/epworth-sleepiness-scale/"
                      tracking="narcolepsy symptom screener, click, ess link top - screener "
                    >
                      Epworth Sleepiness Scale (ESS
                    </SiteLink>
                    <span className="purple-paren">)</span> and/or the{' '}
                    <SiteLink
                      className="inline-link"
                      to="/screening-and-diagnosis/swiss-narcolepsy-scale/"
                      tracking="narcolepsy symptom screener, click, sns link top - screener "
                    >
                      Swiss Narcolepsy Scale (SNS
                    </SiteLink>
                    <span className="purple-paren">)</span> and use the results to help guide your discussion.
                    <sup>1,</sup>
                    <sup>2 </sup>
                  </p>
                </div>
                <div>
                  <LearnMoreComponent learnMoreContent={this.learnMoreContent} />
                  <p className="aasm-footnote">
                    AASM, American Academy of Sleep Medicine.
                  </p>
                </div>
              </div>
            </div>
            <div className="section-gradient" />
            <div className="primary-container quiz-section">
              <div ref={this.essRef} />
              <h2 className="section-header">Measure Your Patient&#8217;s Sleepiness</h2>
              <div className="header-wrapper">
                <h2 className="tertiary-header sleepiness-header">
                  Epworth Sleepiness Scale<sup>1</sup>
                </h2>
              </div>
              <p className="quiz-description ess-description">
                How likely are you to doze off or fall asleep in the following situations, in contrast to just feeling tired? This refers to your usual way of life recently. Even if you haven’t done some of these things recently try to work out how they would have affected you.
              </p>
              <p className="quiz-description ess-description">
                Use the following scale to choose the <strong>most appropriate number</strong> for each situation.
              </p>
              <h4 class="rate-header italic">It is important that you answer each item as best as you can.</h4>

              <EssScreenerComponent selectResponse={this.setResponse} />
              <div className="calc-box">
                <div className="calc-explainer">Higher scores are associated with more daytime sleepiness.</div>
                <div className="calc-total-wrapper">
                  <button className="btn-primary arrow-button" onClick={() => this.captureOptionalInfo('ess')}>
                    Calculate <i className="chevron" />
                  </button>
                  <div className="calc-total">Total Score = {this.state.EssScore}</div>
                </div>
              </div>
              <p className="sources-text sources-text__first">
                ESS &copy; MW Johns 1990-1997. Used under License
              </p>
              <p className="sources-text">For any information on the distribution rights for the ESS, please contact Mapi Research Trust, Lyon, France. Internet: https://eprovide.mapi-trust.org</p>
              <CSSTransition in={!this.state.resultsHidden} timeout={{ enter: 3000, exit: 3000 }} classNames="animation">
                <div id="scroll-to-results-ess">
                  <div className="optional-results-wrapper">
                    {this.state.essCalculated ? (
                      <ResultsComponent
                        calculateValues={this.calcValues}
                        EssScore={this.state.EssScore}
                        EssScores={this.EssScores}
                        essCalculated={this.state.essCalculated}
                        resultsHidden={!this.state.resultsHidden}
                        showResultsModal={this.showResultsModal}
                        showEssEmailModal={this.state.showEssEmailModal}
                        isEssResults={this.state.isEssResults}
                        handleCloseModal={this.handleCloseModal}
                        startSetup={this.state.startSetup}
                        setAutocompleteStartup={this.setAutocompleteStartup}
                        showEssDownloadModal={this.state.showEssDownloadModal}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </CSSTransition>
              <div className="section-gradient section-two-gradient" />
              <h2 className="section-header section-two" ref={this.snsRef}>
                Explore Your Patient&#8217;s Symptoms
              </h2>
              <div className="header-link-wrapper">
                <h2 className="tertiary-header">
                  Swiss Narcolepsy Scale<sup>2,5</sup>
                </h2>{' '}
                <SiteLink to="/screening-and-diagnosis/swiss-narcolepsy-scale/" tracking="narcolepsy symptom screener, click, sns link learn more ">
                  Learn more about the SNS
                </SiteLink>
                <span className="desktop-only" style={{margin: "0px 10px 0px 20px", fontSize: "24px", color: "#ccc", position: "relative", top: "0.15em"}}>|</span> 
                <SiteLink to="/resources/resource-center/" tracking="narcolepsy symptom screener, click, sns screener download tools - clinical resources">
                  Download screening tools
                </SiteLink>
              </div>
              <p className="quiz-description">
                This tool was created to screen individuals for narcolepsy with cataplexy. Ask your patient to answer
                these questions.
              </p>
              <SnsScreenerComponent selectResponse={this.setResponse} />
              <div className="calc-box">
                <div className="calc-explainer sns-calc">
                  Negative scores are suggestive of narcolepsy with cataplexy.
                </div>
                <div className="calc-total-wrapper">
                  <button className="btn-primary arrow-button" onClick={() => this.captureOptionalInfo('sns')}>
                    Calculate <i className="chevron" />
                  </button>
                  <div className="calc-total">Total Score = {this.state.SnsScore}</div>
                </div>
              </div>
              <p className="copyright">
                This copyrighted material is reproduced with permission of the authors. Unauthorized copying, printing, or
                distribution is strictly prohibited.
              </p>
              <CSSTransition in={!this.state.resultsHidden} timeout={{ enter: 3000, exit: 3000 }} classNames="animation">
                <div id="scroll-to-results-sns">
                  {this.state.snsCalculated ? (
                    <ResultsComponent
                      calculateValues={this.calcValues}
                      SnsScore={this.state.SnsScore}
                      SnsScores={this.SnsScores}
                      snsCalculated={this.state.snsCalculated}
                      resultsHidden={!this.state.resultsHidden}
                      showResultsModal={this.showResultsModal}
                      showSnsEmailModal={this.state.showSnsEmailModal}
                      isEssResults={this.state.isEssResults}
                      handleCloseModal={this.handleCloseModal}
                      startSetup={this.state.startSetup}
                      setAutocompleteStartup={this.setAutocompleteStartup}
                      showSnsDownloadModal={this.state.showSnsDownloadModal}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </CSSTransition>
            </div>
            <div className="primary-container">
              <div className="line-break" />
              {this.state.calculated ? (
                <div className="link-button">
                  <button onClick={this.calcValues}>
                    Calculate
                    <span className="button-arrow">
                      <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                    </span>
                  </button>
                </div>
              ) : null}
            </div>
            <CSSTransition in={!this.state.resultsHidden} timeout={{ enter: 3000, exit: 3000 }} classNames="animation">
              <div id="scroll-to-results">
                <div className="primary-container">
                  {this.state.calculated ? (
                    <div>
                      <div className="screen-link-wrapper">
                        <div>
                          <SiteLink
                            to="/pediatric-patients/screening/"
                            className="link-text"
                            tracking="narcolepsy symptom screener,	click,	ess chad - ess results cta"
                          >
                            Screen a pediatric patient
                            <span className="button-arrow">
                              <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                            </span>
                          </SiteLink>
                        </div>
                        <p>
                          These screening tools are not intended to make a narcolepsy diagnosis or replace complete
                          evaluation by a sleep specialist.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
                {this.state.calculated ? (
                  <div className="optional-results-wrapper">
                    <ShareResultsExplanation />
                  </div>
                ) : null}
              </div>
            </CSSTransition>
            <div className="primary-container">
              <div className="disclaimer">
                <p>
                  *These screening tools are not intended to make a narcolepsy diagnosis or replace complete evaluation by
                  a sleep specialist.
                </p>
                <br />
                <p>
                  The content on this site is not meant to replace a conversation with a sleep specialist. A sleep
                  specialist can evaluate for symptoms and make a diagnosis. A narcolepsy diagnosis should be established
                  with a clinical interview and nighttime polysomnography (PSG) followed by a multiple sleep latency test
                  (MSLT).<sup>7</sup>
                </p>
              </div>
              <ReadNext readNext={readNext} />
            </div>
            <ReferenceComponent referenceList={references} />
          </div>
          <Modal show={this.state.showFinishQuestionsModal} className="content-modal base-modal-screener">
            <div className="modal-content-wrapper">
              <div className="close-modal-x" onClick={() => this.handleCloseModal()}>
                X
              </div>
              <h2 className="finish-questions-title">
                Please fill out all the questions on the screener to see your result.
              </h2>
              <div className="action finish">
                <button className="button btn-primary btn-close-modal" onClick={() => this.handleCloseModal()}>
                  Back
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            className="content-modal base-modal-screener"
            show={this.state.showOptionalInfoModal}
            onHide={() => this.handleCloseModal('optional')}
          >
            <div className="modal-content-wrapper">
              <Modal.Body>
                <OptionalInformation
                  genderTracking={this.optionalInfoGenderTracking}
                  ageTracking={this.optionalInfoAgeTracking}
                />
                <button className="button btn-primary btn-close-modal" onClick={this.calculate}>
                  Continue
                </button>
              </Modal.Body>
            </div>
          </Modal>
          {/* <div className="btn-primary btn-calculating">
            Calculating <FontAwesomeIcon className="loading" icon={faSpinner} spin />
          </div> */}
        </div>
      </Layout>
    )
  }
  isIos(isIdevice) {
    return isIdevice ? 'margin-zero' : ''
  }
  isCalculating(calculating) {
    return calculating ? 'background-overlay' : 'no-overlay'
  }
}

export default SymptomScreener
