import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SiteLink from '@components/SiteLink'
import pdfConversationStarter from '@assets/pdfs/narcolepsy-conversation-starter.pdf'

import { trackGa } from '@components/GoogleTracking'
import './share-results-explanation.component.scss'

const ShareResultsExplanation = () => {
  return (
    <div className="share-results">
      <div className="share-explanation">
        <h2 className="section-header">Share These Results With a Sleep Specialist</h2>
        <p>
          The Epworth Sleepiness Scale and the Swiss Narcolepsy Scale can provide important information for a sleep
          specialist in screening for excessive daytime sleepiness and cataplexy in narcolepsy. Have your patient share
          these important scores with a sleep specialist and review the diagnostic sleep tests that help identify
          narcolepsy.
        </p>
        <p>
          The Narcolepsy Conversation Starter is a useful tool for your patients when talking about narcolepsy.{' '}
          <a
            href={pdfConversationStarter}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-link"
            onClick={() => {
              trackGa('narcolepsy symptom screener, click, download narcolepsy conversation starter - screener callout')
            }}
          >
            Download the PDF
          </a>{' '}
          and share it with your patients to help them talk about their symptoms with a sleep specialist. To find a
          sleep specialist and refer your patients, use the{' '}
          <SiteLink
            to="/stay-connected/locate-a-sleep-specialist/"
            className="inline-link"
            tracking="narcolepsy symptom screener, click, locate sleep specialist link - screener"
          >
            Sleep Specialist locator
          </SiteLink>.
        </p>
      </div>
      <div className="share-results-link-wrapper">
        <div className="share-results-link">
          <SiteLink
            to="/stay-connected/locate-a-sleep-specialist/"
            tracking="narcolepsy symptom screener, click, locate sleep specialist button - ess"
          >
            <div className="page-name">locate a nearby sleep specialist</div>
            <FontAwesomeIcon className="arrow-right" icon="angle-right" />
          </SiteLink>
        </div>
      </div>
    </div>
  )
}

export default ShareResultsExplanation
